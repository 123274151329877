import { useGetTenantDetailsQuery, useGetUserDetailsQuery } from '@/redux/features/users/userApi'
import User_store from '@/store/User_store'
import { getAuthKey } from 'constant/functions'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import { pricing_plans } from 'data/PringPlanData'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const HomePricingPlan = () => {
  const router = useRouter()
  const { data: userDetails } = useGetUserDetailsQuery({})
  const { data: tenantDetails } = useGetTenantDetailsQuery({})

  const [plans] = useState(pricing_plans.slice(0, 3))

  return (
    <div className=" max-w-[1280px] px-6 mx-auto mt-[80px]">
      <h2 className=" text font-semibold  md:font-bold text-[32px] leading-10   mb-12  text-center">
        Start Free and Pay as you grow
      </h2>
      <div className="grid grid-cols-12 gap-6">
        {/* Tab 1 */}
        {plans.map((item, index, array) => {
          return (
            <div
              className="relative col-span-full  sm:col-span-6 lg:col-span-4 xl:col-span-4 bg-white shadow-md rounded-lg border border-gray-200"
              key={item.id}
            >
              <div className="w-full  h-full px-6 py-8  flex flex-col   ">
                <div className=" mb-6 flex-grow">
                  <h3 className="text-[24px] text-gray-800 font-bold mb-4">{item.title}</h3>

                  <p className="text-sm  ">{item.description}</p>
                </div>

                {/* CTA */}
                <button
                  onClick={() => {
                    MixpanelTracking(MixpanelEventName.pricing_page, {
                      [MixpanelPropertiesName.user_name]: userDetails?.name,
                      [MixpanelPropertiesName.user_email]: userDetails?.email,
                      [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
                      [MixpanelPropertiesName.pricing_page_action]:
                        MixpanelPropertiesValues.get_start,
                    })
                    getAuthKey() === undefined && localStorage.setItem('selected_plan', item.id)
                    router.push(item?.link)
                  }}
                  className="btn text-white bg-blue-700 hover:bg-blue-900 w-full"
                >
                  {item.btn_title}
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HomePricingPlan
