import { getAuthKey } from 'constant/functions'

export const pricing_plans = [
  {
    _id: 1,
    id: 'community_monthly',
    fresh_work_title: 'Community',
    title: 'Community',
    price: { dollar_sign: '$', title: 'month', true: '0', false: '0' },
    yearly_charge: 'Charged now - $0/yr',
    description:
      'Start without any base fee. For every community member to get their concept into a product.',
    includes: ['No transaction fee', 'Community Support', 'Free Tradly Hosted Website'], //! deprecated
    features: [
      {
        title: 'No transaction fee',
        info: '',
        link: '',
      },
      {
        title: 'Community support',
        info: '',
        link: '',
      },
      {
        title: 'Free Tradly hosted website',
        info: '',
        link: '',
      },
      {
        title: 'Add your custom domain',
        info: '',
        link: '',
      },
      {
        title: 'Editor - 3 Day page history',
        info: '',
        link: '',
      },
    ],
    history_timeline: 3,
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    btn_title: 'Start Building For Free',
  },
  {
    _id: 2,
    id: 'starter_monthly',
    fresh_work_title: 'Starter',
    title: 'Starter',
    price: { dollar_sign: '$', title: 'month', true: '17', false: '31' },
    yearly_charge: 'Charged now - $204/yr',
    description:
      'For Individual & Startups validating their idea. Comes with all essential to validate with your target audience.',
    includes: [
      'No transaction fee',
      'Community + Livechat + Emails',
      'No Tradly Branding in website',
    ], //! deprecated
    features: [
      {
        title: 'No transaction fee',
        info: '',
        link: '',
      },
      {
        title: '+Limited Support',
        info: '3 Days response time for non-critical',
        link: '/pricing/marketplace#support',
      },
      {
        title: 'No Tradly Branding in website',
        info: '',
        link: '',
      },
      {
        title: 'Unlimited Custom Attributes',
        info: '',
        link: '',
      },
      {
        title: '30 Day page history',
        info: '',
        link: '',
      },
    ],
    history_timeline: 30,
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    btn_title: ' Start 7 Days Trial',
  },
  {
    _id: 3,
    id: 'growth_monthly',
    fresh_work_title: 'Growth',
    title: 'Growth',
    price: { dollar_sign: '$', title: 'month', true: '54', false: '99' },
    yearly_charge: 'Charged now - $648/yr',
    description:
      ' For proven business on their growth Stage. Have custom needs and additional integrations to grow.',
    includes: [
      'No transaction fee',
      'Slack Private Channel',
      'Custom "From Emails"',
      'Additional Mini Tools & Integrations',
    ], //! deprecated
    features: [
      {
        title: 'No transaction fee',
        info: '',
        link: '',
      },
      {
        title: '+ Support Portal',
        info: '48 Hours response time for non-critical',
        link: '/pricing/marketplace#support',
      },
      {
        title: "Custom 'From Email'",
        info: '',
        link: '',
      },
      {
        title: 'Additional Mini Tools & Integrations',
        info: '',
        link: '',
      },
      {
        title: 'Personalised Onboarding calls*',
        info: '',
        link: '',
      },
      {
        title: '90 Day page history',
        info: '',
        link: '',
      },
      {
        title: 'Multi Currencies & Multi Languages',
        info: '',
        link: '',
      },
      {
        title: 'Gated Subscription',
        info: 'Subscription charges for people to open accounts & submit listings',
        link: '',
      },
      {
        title: 'Social logins',
        info: 'Signup/in via Google, Meta',
        link: '',
      },
      {
        title: 'Webooks',
        info: 'To connect with Zapier & Make',
        link: '',
      },
      {
        title: 'Role-based access control (RBAC)',
        info: '',
        link: '',
      },
    ],
    history_timeline: 90,
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    btn_title: ' Start 7 Days Trial',
    is_show_star: true,
  },

  {
    _id: 4,
    id: 'scale_monthly',
    fresh_work_title: 'Scale (previously business)',
    title: 'Scale',
    price: { dollar_sign: '$', title: 'month', true: '164', false: '299' },
    yearly_charge: 'Charged now - $1968/yr',
    description:
      'Business who are in growth stage and looking to have be-spoke solution and other custom requirements.',
    includes: ['No transaction fee', 'Priority Support', 'Multiple Channels(App Support)', 'RBAC'], //! deprecated
    history_timeline: 'unlimited',
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    features: [
      {
        title: 'No transaction fee',
        info: '',
        link: '',
      },
      {
        title: 'Priority Support',
        info: '24 hour response time for non-critical',
        link: '/pricing/marketplace#support',
      },
      {
        title: 'Multiple Channels (App Support)',
        info: '',
        link: '',
      },
      
      {
        title: 'Single Sign-on (SSO)',
        info: '',
        link: '',
      },
      
      {
        title: 'Unlimited page history',
        info: '',
        link: '',
      },
    ],
    btn_title: ' Start 7 Days Trial',
  },
]
